class AbstractSiteConstants {
  // Poor man's abstract function implementations
  getSearchRoute() {
    throw new TypeError(
      this.constructor.name + ' must implement getSearchRoute()'
    );
  }

  getEnterpriseAccountManagerEmail() {
    return 'kelly@storyblocks.com';
  }

  getDetailsRoute() {
    throw new TypeError(
      this.constructor.name + ' must implement getDetailsRoute()'
    );
  }

  getCollectionsBaseRoute() {
    throw new TypeError(
      this.constructor.name + ' must implement getCollectionsBaseRoute()'
    );
  }

  getCollectionsRoute() {
    throw new TypeError(
      this.constructor.name + ' must implement getCollectionsRoute()'
    );
  }

  shouldUseLayoutBlocks() {
    throw new TypeError(
      this.constructor.name + ' must implement shouldUseLayoutBlocks()'
    );
  }

  getSiteName() {
    throw new TypeError(
      this.constructor.name + ' must implement getSiteName()'
    );
  }

  getAbbreviatedSiteName() {
    throw new TypeError(
      this.constructor.name + ' must implement getAbbreviatedSiteName()'
    );
  }

  getStockItemName() {
    throw new TypeError(
      this.constructor.name + ' must implement getStockItemName()'
    );
  }

  getStockNounSingular() {
    throw new TypeError(
      this.constructor.name + ' must implement getStockNounSingular()'
    );
  }

  getProductName() {
    throw new TypeError(
      this.constructor.name + ' must implement getProductName()'
    );
  }

  //TODO [one-url] Kill this when we refactor down product specific DataBridge and DownloadController
  getDataBridge() {
    throw new TypeError(
      this.constructor.name + ' must implement getDataBridge()'
    );
  }

  getDownloadHistoryApiRoute() {
    return '/api/member/download-history';
  }

  getYoutubeChannelValidationApiRoute() {
    return '/api/member/validate-youtube-channel';
  }

  getFoldersApiBasePath() {
    return '/api/member/folders';
  }

  getFoldersBasePath() {
    return '/member/folders';
  }

  getFoldersFeedbackBasePath() {
    return '/folders/feedback';
  }

  getSubmitFolderFeedbackRoute() {
    return '/api/folders/feedback';
  }

  getStockMediaNavOptions() {
    throw new TypeError(
      this.constructor.name + ' must implement getStockMediaNavOptions'
    );
  }

  getStoryboardsBasePath() {
    return '/storyblocks-with-ai';
  }

  getStoryboardsApiBasePath() {
    return '/api/storyboards';
  }

  getStoryboardsSceneSearchBasePath() {
    return '/storyblocks-with-ai/scene/search';
  }

  getStoryboardsSceneSearchApiBasePath() {
    return '/api/storyboards/scene';
  }

  getStoryboardMarketingPath() {
    return '/storyboard-ai/about/294787123091230987474390';
  }
}

export default AbstractSiteConstants;
