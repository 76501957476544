import { HTTPMethod } from './Constants';
import SiteConstants from './SiteConstants/SiteConstants';

class AbstractAPI {
  static buildFetchOptions(
    method: HTTPMethod = HTTPMethod.GET,
    postParameters?: object
  ): RequestInit {
    const options: Partial<RequestInit> = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json, text/plain, */*',
      },
      method,
    };

    if (postParameters) {
      options.body = JSON.stringify(postParameters);
      options.headers['Content-Type'] = 'application/json';
    }

    if (typeof __PAGE_TRACE_ID__ !== 'undefined') {
      options.headers['tracestate'] = `page-trace-id=${__PAGE_TRACE_ID__}`;
    }

    return options;
  }

  static getBaseUrl() {
    const shortSite = SiteConstants.getInstance().getAbbreviatedSiteName();

    // window is undefined when we're doing server-side rendering
    return typeof window !== 'undefined' &&
      typeof window.location !== 'undefined'
      ? window.location.protocol + '//' + window.location.host
      : 'https://' + shortSite + '.local';
  }

  static request(url: string, options?: RequestInit) {
    return fetch(url, options).then((response) => {
      if (!response.ok) {
        return response.json().then((json) => {
          const error = new Error(
            response.status + ': ' + response.statusText + ' - ' + json.message
          );
          // @ts-expect-error establishing a property instead of reading it
          error.json = json;

          throw error;
        });
      } else {
        return response;
      }
    });
  }
}

export default AbstractAPI;
