import AbstractSiteConstants from './AbstractSiteConstants';

export type ImageNavOptions = ReturnType<
  ImageSiteConstants['getStockMediaNavOptions']
>;

let dataBridge;

class ImageSiteConstants extends AbstractSiteConstants {
  getSearchRoute() {
    return '/images/search';
  }

  getDetailsRoute() {
    return '/images/stock/:urlId';
  }

  getCollectionsBaseRoute() {
    return '/images/collections';
  }

  getCollectionsRoute() {
    return `${this.getCollectionsBaseRoute()}/:name`;
  }

  getUpgradeMyPlanBasePath() {
    return '/images/upgrade-my-plan';
  }

  getPortfolioBaseApiPath() {
    return '/api/images/portfolio';
  }

  getPortfolioBasePath() {
    return '/images/portfolio';
  }

  getCancelApiBasePath() {
    return '/api/images/member/cancel-membership';
  }

  getCollectionsPath() {
    return '/api/images/collections';
  }

  getCollectionsManyPath() {
    throw new Error('Collections many path does not exist for images');
  }

  getSearchApiBasePath() {
    return '/api/images/search';
  }

  getDetailsReportErrorBasePath() {
    return '/api/images/reporterror/';
  }

  shouldUseLayoutBlocks() {
    return true;
  }

  getSiteName() {
    return 'Storyblocks';
  }

  getAbbreviatedSiteName() {
    return 'gs';
  }

  getProductName() {
    return 'Images';
  }

  getStockItemName() {
    return 'Image';
  }

  getStockNounSingular() {
    return 'image';
  }

  async getDataBridge() {
    if (!dataBridge) {
      const mod = await import('../../DataBridge/GraphicDataBridge');
      dataBridge = new mod.default();
    }

    return dataBridge;
  }

  getStockMediaNavOptions() {
    return {
      VECTORS: 'vectors',
      ILLUSTRATIONS: 'illustrations',
      PHOTOS: 'photos',
    };
  }

  getMinDuration() {
    return null;
  }

  getMaxDuration() {
    return null;
  }

  getMinTempo() {
    return null;
  }

  getMaxTempo() {
    return null;
  }
}

export default ImageSiteConstants;
