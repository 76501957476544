import { getCurrentSite } from '../utils';
import AudioSiteConstants from './AudioSiteConstants';
import ImageSiteConstants from './ImageSiteConstants';
import VideoSiteConstants from './VideoSiteConstants';

let instance = null;

export const STORYBLOCKS = 'storyblocks';
export const VIDEOBLOCKS = 'videoblocks';
export const AUDIOBLOCKS = 'audioblocks';
export const GRAPHICSTOCK = 'graphicstock';

export enum SiteEnum {
  Storyblocks = 'storyblocks',
  Videoblocks = 'videoblocks',
  Audioblocks = 'audioblocks',
  Graphicstock = 'graphicstock',
  Templates = 'templates',
}

export enum HomeRoutes {
  Videoblocks = '/video',
  Audioblocks = '/audio',
  Graphicstock = '/images',
}

export enum MediaTypesRoutes {
  VideoFootage = '/video/footage',
  VideoMotionBackgrounds = '/video/motion-backgrounds',
  VideoAeTemplates = '/video/templates/after-effects',

  ImagesVectors = '/images/vectors',
  ImagesPhotos = '/images/photos',
  ImagesIllustrations = '/images/illustrations',

  AudioMusic = '/audio/music',
  AudioSfx = '/audio/sound-effects',
}
export const BLOG_BASE_URL = 'https://www.storyblocks.com/resources';

export enum BlogResourceTypes {
  Blog = '/blog',
  CaseStudies = '/case-studies',
  Courses = '/courses',
  Guides = '/guides',
  News = '/news',
  Tutorials = '/tutorials',
  Webinars = '/webinars',
}

export const INDIVIDUAL_LICENSE_NAME = 'Individual';
export const ENTERPRISE_LICENSE_NAME = 'Enterprise';
export const BUSINESS_LICENSE_NAME = 'Business';

class SiteConstants {
  static getInstance():
    | AudioSiteConstants
    | ImageSiteConstants
    | VideoSiteConstants {
    if (!instance) {
      instance = SiteConstants.buildInstance();
    }

    return instance;
  }

  static buildInstance(site?: SiteEnum) {
    if (!site) {
      site = getCurrentSite();
    }

    switch (site) {
      case SiteEnum.Graphicstock:
      case SiteEnum.Storyblocks:
        return new ImageSiteConstants();

      case SiteEnum.Videoblocks:
        return new VideoSiteConstants();

      case SiteEnum.Audioblocks:
        return new AudioSiteConstants();

      default:
        return null;
    }
  }

  static searchResultsLimit() {
    return 10000;
  }

  static getIndividualLicenseName() {
    return INDIVIDUAL_LICENSE_NAME;
  }

  static getBusinessLicenseName() {
    return BUSINESS_LICENSE_NAME;
  }

  static getLicenseComparisonRoute() {
    return '/license-comparison';
  }

  static getMinDuration() {
    return 0;
  }

  static getIntercomHelpCenterUrl() {
    return 'https://help.storyblocks.com';
  }

  static getBlogUrl(resourceType: BlogResourceTypes = BlogResourceTypes.Blog) {
    return `${BLOG_BASE_URL}${resourceType}`;
  }
}

export default SiteConstants;
