export enum ContentClass {
  Video = 'video',
  Audio = 'audio',
  Image = 'image',
}

export enum ContentType {
  Footage = 'footage',
  Template = 'template',
  Motion_bg = 'motion-background',
  Vr_360 = 'vr360',
  Illustration = 'illustration',
  Vector = 'vector',
  Photo = 'photo',
  Sound_effect = 'sfx',
  Music = 'music',
}
