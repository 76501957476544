import AbstractSiteConstants from './AbstractSiteConstants';

export type AudioNavOptions = ReturnType<
  AudioSiteConstants['getStockMediaNavOptions']
>;

let dataBridge;

class AudioSiteConstants extends AbstractSiteConstants {
  getSearchRoute() {
    return '/audio/search';
  }

  getDetailsRoute() {
    return '/audio/stock/:urlId';
  }

  getCollectionsBaseRoute() {
    return '/audio/collections';
  }

  getCollectionsRoute() {
    return `${this.getCollectionsBaseRoute()}/:name`;
  }

  getUpgradeMyPlanBasePath() {
    return '/audio/upgrade-my-plan';
  }

  getPortfolioBasePath() {
    throw new Error('Contributor Portfolio Pages do not exist for Audio');
  }

  getPortfolioBaseApiPath() {
    throw new Error('Contributor Portfolio Pages do not exist for Audio');
  }

  getCancelApiBasePath() {
    return '/api/audio/member/cancel-membership';
  }

  getCollectionsPath() {
    return '/api/audio/collections';
  }

  getCollectionsManyPath() {
    throw new Error('Collections many path does not exist for audio');
  }

  getSearchApiBasePath() {
    return '/api/audio/search';
  }

  getDetailsReportErrorBasePath() {
    return '/api/audio/reporterror/';
  }

  shouldUseLayoutBlocks() {
    return false;
  }

  getSiteName() {
    return 'AudioBlocks';
  }

  getAbbreviatedSiteName() {
    return 'ab';
  }

  getProductName() {
    return 'Audio';
  }

  getStockItemName() {
    return 'Sound';
  }

  getMaxTempo() {
    return 250;
  }

  getMinTempo() {
    return 0;
  }

  getMaxDuration() {
    return 240;
  }

  getMinDuration() {
    return 0;
  }

  static getDefaultNumberOfResultsPerPage() {
    return 36;
  }

  getStockNounSingular() {
    return 'audio';
  }

  async getDataBridge() {
    if (!dataBridge) {
      const mod = await import('../../DataBridge/AudioDataBridge');
      dataBridge = new mod.default();
    }

    return dataBridge;
  }

  getStockMediaNavOptions() {
    return {
      MUSIC: 'royalty-free-music',
      EFFECTS: 'sound-effects',
    };
  }
}

export default AudioSiteConstants;
