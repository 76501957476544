// https://www.30secondsofcode.org/js/s/slugify
import { ContentClass } from '../enums';

export const slugify = (str = '') =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // remove non-word, non-space characters before `-`
    .replace(/[\s+-]+/g, '-') // internal spaces to `-`
    .replace(/^-+|-+$/g, ''); // remove (leading) `-`

export function titleCase(str) {
  const splitStr = str.toLowerCase().split(' ');
  const titleCased = splitStr.map(
    (word) => word.charAt(0).toUpperCase() + word.substring(1)
  );

  // Directly return the joined string
  return titleCased.join(' ');
}

export const isCollectionItemNew = (collectionItem) => {
  if (!collectionItem || !collectionItem.dateAdded) {
    return false;
  }
  const dateAdded = collectionItem.dateAdded;
  const utcDateAdded = new Date(dateAdded);
  const millisecondsInASecond = 1000;
  const secondsInADay = 86400;
  const dateToNumber = (date = new Date()): number => Date.parse(String(date));

  const getDaysSinceDateAdded = Math.floor(
    Math.abs(dateToNumber(utcDateAdded) - dateToNumber()) /
      millisecondsInASecond /
      secondsInADay
  );

  return getDaysSinceDateAdded < 100;
};

export function urlSegmentForContentClass(contentClass: ContentClass): string {
  switch (contentClass) {
    case ContentClass.Video:
      return 'video';
    case ContentClass.Audio:
      return 'audio';
    case ContentClass.Image:
      return 'images';
    default:
      return null;
  }
}
