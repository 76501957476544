import AbstractSiteConstants from './AbstractSiteConstants';

export type VideoNavOptions = ReturnType<
  VideoSiteConstants['getStockMediaNavOptions']
>;

let dataBridge;

class VideoSiteConstants extends AbstractSiteConstants {
  getSearchRoute() {
    return '/:path(all-video/.*|motion-graphics/.*|video/.*|templates/.*)';
  }

  getDetailsRoute() {
    return '/video/stock/:urlId';
  }

  getCollectionsBaseRoute() {
    return '/video/collections';
  }

  getCollectionsRoute() {
    return `${this.getCollectionsBaseRoute()}/:name`;
  }

  getUpgradeMyPlanBasePath() {
    return '/video/upgrade-my-plan';
  }

  getPortfolioBasePath() {
    return '/video/portfolio';
  }

  getPortfolioBaseApiPath() {
    return '/api/video/portfolio';
  }

  getCancelApiBasePath() {
    return '/api/video/member/cancel-membership/';
  }

  getCollectionsPath() {
    return '/api/video/collections';
  }

  getCollectionsManyPath() {
    return '/api/video/collections-many';
  }

  getSearchApiBasePath() {
    return '/api/video/search';
  }

  getDetailsReportErrorBasePath() {
    return '/api/video/reporterror/';
  }

  shouldUseLayoutBlocks() {
    return false;
  }

  getSiteName() {
    return 'VideoBlocks';
  }

  getAbbreviatedSiteName() {
    return 'vb';
  }

  getProductName() {
    return 'Video';
  }

  getStockItemName() {
    return 'Clip';
  }

  getStockNounSingular() {
    return 'video';
  }

  async getDataBridge() {
    if (!dataBridge) {
      const mod = await import('../../DataBridge/VideoDataBridge');
      dataBridge = new mod.default();
    }

    return dataBridge;
  }

  getMinDuration() {
    return 0;
  }

  getMaxDuration() {
    return 60;
  }

  getMinTempo() {
    return null;
  }

  getMaxTempo() {
    return null;
  }

  getStockMediaNavOptions() {
    return {
      STOCK_FOOTAGE: 'stock-footage',
      BACKGROUNDS: 'animated-backgrounds',
      TEMPLATES: 'Templates',
    };
  }
}

export default VideoSiteConstants;
