import { SearchFilterContentTypes } from '@videoblocks/shared-components';

import { Category } from '../../Details/DetailsTypes';
import { ContentClass } from '../../app/Shared/enums';

export type StockItemDetails = {
  genres?: object[];
  isFavorite: boolean;
  moods?: Category[];
  selectedAcquisitionOption?: object[];
  stockItem: StockItem;
  stockItemArtists?: object[];
  stockItemFormats?: StockItemFormat[];
  topTags?: object[];
};

interface DownloadFormats {
  format: string;
  downloadUrl: string;
}

export interface DetailsKeywords {
  keyword: string;
  url: string;
}

export type Contributor = {
  portfolioLink: string;
  username: string;
};

export type Color = { hex_code: string; percentage: number };

export type StockItem = Partial<{
  description: string;
  maxResolution: number;
  searchType: SearchFilterContentTypes;
  contributor: Contributor;
  isActive: boolean;
  hasRecommendedMusic: boolean;
  downloadFormats: DownloadFormats[];
  isTemplate: boolean;
  keywords: DetailsKeywords[];
  categories: Category[];
  is360: boolean;
  shouldStockItemHaveNewFlag: boolean;
  mediumImageUrl: string;
  largeImageUrl: string;
  previewUrl: string;
  smallPreviewUrl: string;
  id: number;
  title: string;
  contentType: string;
  thumbnailUrl: string;
  detailsUrl: string;
  contentClass: ContentClass;
  assetId: string;
  contentId: number;
  dateLastAccessed: string;
  ownerName: string;
  isMarketPlaceItem: boolean;
  distributionType: string;
  isSavingFavorite: boolean;
  collapsedSetId: string;
  type: string;
  mediaType: string;
  moods: Category[];
  genres: Category[];
  instruments: Category[];
  duration: number;
  numCollapses: number;
  stockLicenseTypeId: number;
  colors: Color[];
  waveformUrl: string;
  shouldStockItemHaveStaffPickFlag: boolean;
  hasVector?: boolean;
  aspectRatio?: number;
  dateAdded: string;
  snapshotVideo?: StockItem;
}>;

export enum StockItemContext {
  SIMILAR_CONTENT = 'similarContent',
  DETAILS_PLAYER = 'detailsPlayer',
  DRAWER = 'drawer',
  FOLDER = 'folder',
  MEDIA_TYPE = 'mediaType',
  SEARCH = 'search',
  GLOBAL_PLAYER = 'globalPlayer',
  CROSS_ASSET_COLLECTION = 'crossAssetCollection',
  CONTENT_TYPE = 'contentType',
  STORYBOARD = 'storyboard',
}

// e.g. StockItemOptionViewDTO + VideoStockItemOptionsViewDTO + GraphicStockItemOptionsViewDTO
export type StockItemFormat = {
  id: string;
  label: string;
  prettyLabel: string;
  filesize: string;
  filename: string;
  resolution: string;
  fileExtension: string;
  quality: string;
  formatName: string;
  downloadAjaxUrl: string;
  downloadUrl: string;
  exportUrl: string;
  frameRate?: string;
  codec?: string;
  dpi?: number;
};
